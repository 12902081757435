import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout/layout";
import HeroPost from "../components/hero/heroPost";
import Seo from "../components/seo/seo";
import Hero from "../components/hero/hero";
import Card from "../components/card/card";
import {
  FaAmazon,
} from 'react-icons/fa';

export default ({ data, location }) => {
  const { item } = data;
  const { brand } = data.item;
  const { category } = data.item;
  const related = data.related.nodes;
  const [ isShow , showToc ] = useState(false);

  const url = `${data.site.siteMetadata.siteUrl}${brand.slug}${category.slug.substring(1)}${item.slug.substring(1)}`;
  const stock = item.price === 0 ? "https://schema.org/OutOfStock" : "https://schema.org/InStock"

  return (
    <Layout title={`Labial ${item.title} - ${brand.title}`} location={location}>
      <Seo title={item.metaTitle} description={item.metaDescription}>
        <link rel="canonical" href={`${data.site.siteMetadata.siteUrl}${brand.slug}${category.slug.substring(1)}${item.slug.substring(1)}`} />
          <script type="application/ld+json">{`
            {
              "@context":"https://schema.org",
              "@type": "Product",
              "name": "${item.title}",
              "description": "${item.metaDescription}",
              "image": "${item.localImage.childImageSharp.original.src}",
              "url": "${data.site.siteMetadata.siteUrl}${brand.slug}${category.slug.substring(1)}${item.slug.substring(1)}",
              "brand": {
                "@type": "Brand",
                "name": "${brand.title}"
              },
              "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": 4,
                "ratingCount": 1,
                "author": {
                    "@type" : "Organization",
                    "name": "${data.site.siteMetadata.title}"
                 }
              },
              "offers": {
                "@type": "Offer",
                "url": "${url}",
                "priceCurrency": "EUR",
                "price": "${item.price}",
                "availability": "${stock}"
              },
              "author": {
                "@type" : "Person",
                "name": "${data.site.siteMetadata.title}"
                },
              "publisher": {
                "@type" : "Organization",
                "name": "${data.site.siteMetadata.title}",
                "logo" : {
                    "@type": "ImageObject",
                    "url": "${data.site.siteMetadata.siteUrl}/favicon.png"
                }
              },
              "datePublished": "${item.date}",
              "dateModified": "${item.updatedAt}"
            }
          `}
          </script>
      </Seo>
      <Hero img={item.localItemHeroImg} title="" description="" />
      <div className="min-vh-100 blog__grid">
        <div className="mw8 serif f4 lh-copy center pa2 article__container" style={{gridArea: "content"}}>
          <HeroPost
            title={item.title}
            description={item.sentence}
            author={item.author}
            date={item.dateFormat}
            brand={{name: brand.title, slug: brand.slug}}
            category={{name: category.title, slug: category.slug}}
            images={item.localImages}
            image={item.localImage}
          />
        </div>
        <div style={{gridArea: "header"}} />


        <div className="mw8 serif f4 lh-copy center pa2 article__container" style={{gridArea: "content"}}>
          <div className="fab-container">
            {item.amazon && <a className="no-underline near-white bg-animate bg-near-black hover-bg-gray inline-flex items-center ma2 tc br2 pa2 amazon-button" href={`https://amzn.to/${item.amazon}`} rel="nofollow ">
                <FaAmazon className="dib h2 w2" aria-label="amazon"/>
              <span className="f6 ml3 pr2 serif">Comprar en Amazon</span>
            </a>}
          </div>
          <div id="toc_container" className={isShow ? "no_bullets showTable" : "no_bullets hideTable"}>
            <p className="toc_title">
              Índice
              <span className="toc_toggle">
               [<a onClick={(e) => showToc(!isShow)}>{isShow ? "Ocultar" : "Mostrar"}</a>]
            </span>
            </p>
            <ul className={isShow ? "toc_list toc_list_show" : "toc_list toc_list_hide"}>
              {item.description && <li>
                <a href="#caracteristicas">💄 Características principales del {item.title}</a>
              </li>}
              <li><a href="#colores">🎨 Tonos y colores del {item.title}</a></li>
              <li><a href="#textura">💋 Textura de los labiales {item.title}</a></li>
              <li><a href="#duracion">⌛ Duración de los labiales {item.title}</a></li>
              {item.videoId && <li>
                <a href="#video">🎥 Video sobre {item.title}</a>
              </li>}
              {item.ingredients && <li>
                <a href="#ingredientes">🔬 Ingredientes</a>
              </li>}
              {related.length > 0 && <li>
                <a href="#relacionados">➡ Labiales Relacionados</a>
              </li>}
            </ul>
          </div>
          {item.description &&
          <>
            <h3 id="caracteristicas">💄 Características principales del {item.title}</h3>
            <p dangerouslySetInnerHTML={{__html: item.description}} />
          </>}
          <h3 id="colores">🎨 Tonos y colores del {item.title}</h3>
          <p dangerouslySetInnerHTML={{__html: item.color !== '' ? item.color : 'Color '+ item.colorFamily}} />
          <h3 id="textura">💋 Textura de los labiales {item.title}</h3>
          <p dangerouslySetInnerHTML={{__html: item.texture !== '' ? item.texture : 'Acabado '+item.finish}} />
          <h3 id="duracion">⌛ Duración de los labiales {item.title}</h3>
          <p dangerouslySetInnerHTML={{__html: item.duration !== '' ? item.duration : 'Duración prolongada'}} />
          {item.videoId &&
          <>
            <h3 id="video">🎥 Video sobre {item.title}</h3>
            <p>
              <iframe width="100%" height="315" src={`https://www.youtube-nocookie.com/embed/${item.videoId}?rel=0"`}
                      frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      title={`Video del labial ${item.title}`}
                      allowFullScreen="" />
            </p>
          </>}
          {item.ingredients &&
            <>
              <h3 className="f6" id="ingredientes">🔬 Ingredientes</h3>
              <p className="f6" dangerouslySetInnerHTML={{__html: item.ingredients}} />
            </>
          }
          {related.length > 0 && <>
            <h3 id="relacionados">➡ Labiales Relacionados</h3>
            <div className="cf pa2">
              {related.map((node, key) => {
                return (
                  <Card
                    key={key}
                    to={brand.slug+category.slug.substring(1)+node.slug.substring(1)}
                    img={node.localImage.childImageSharp.fixed}
                    title={node.title}
                    brand={brand.title}/>
                )
              })}
            </div>
          </>}
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
    query($slug: String!, $category: String!, $brand: String!) {
        item: items(slug: {eq: $slug}) {
            date
            author
            metaDescription
            metaTitle
            title
            dateFormat: updatedAt(formatString:"MMMM DD, YYYY", locale: "es")
            updatedAt
            texture
            slug
            duration
            description
            date
            color
            colorFamily
            finish
            ingredients
            videoId
            localImages {
                childImageSharp {
                    fixed(width: 200, height: 200) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            sentence
            brand {
                title
                slug
            }
            category {
                title
                slug
            }
            localImage {
                childImageSharp {
                    original {
                        src
                    }
                    fixed(width: 200, height: 200) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            localItemHeroImg {
                childImageSharp {
                    fluid(maxWidth: 1080, maxHeight: 300) {
                        ...GatsbyImageSharpFluid
                    }
                }
            }
            amazon
            price
        }
        related: allItems(filter: {brand: {slug: {eq: $brand}}, category: {slug: {eq: $category}}, slug: {ne: $slug}}) {
            nodes {
                slug
                title
                brand {
                    id
                    title
                    slug
                }
                localImage {
                    childImageSharp {
                        fixed(width: 40, height: 120) {
                            ...GatsbyImageSharpFixed
                        }
                    }
                }
            }
        }
        site {
            siteMetadata {
                title
                siteUrl
                facebook
                instagram
                twitter
            }
        }
    }
`
