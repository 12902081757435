import React from 'react';
import { Link } from 'gatsby';

const Breadcrumb = ({paths = [], current}) => (
  <div className="w-100 f6 pv3 flex items-center tracked ttu sans-serif justify-around mw7">
    <Link
      to="/"
      className="dark-gray">Inicio</Link>
    {paths.map((path, key) =>
      <React.Fragment key={key}>
        <span>&nbsp;>&nbsp;</span>
        {path.path != null && <Link
          to={path.path}
          className="dark-gray">
          {path.name}</Link>}
        {path.path == null && path.name}
      </React.Fragment>
    )}
    <span>&nbsp;>&nbsp;</span>
    <span className="mid-gray">{current}</span>
  </div>
);

export default Breadcrumb;
