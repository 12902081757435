import React from 'react';
import Img from "gatsby-image";
import Breadcrumb from "../breadcrumb/breadcrumb";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const HeroPost = ({ title, description, brand, category, author, date, images, image }) => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "linear"
  };
  return (
    <>
      <div className="ph7-l ph5-m ph2 flex flex-column justify-center items-center">
        <Breadcrumb current={title} paths={[
          {name: brand.name, path: brand.slug},
          {name: category.name},
        ]}/>
      </div>
      <h2 className="pa1 display dark-gray f3 tc mb1 h3">{title}</h2>
      <div className="w-100 flex items-center">
        <div className="w-50 pa3">
          <div className="container">
            <Slider {...settings}>
              <div className="v-mid tc">
                <Img fixed={image.childImageSharp.fixed}/>
              </div>
              {images.map((img, index) => (
                <div className="v-mid tc" key={index}>
                  <Img
                    fixed={img.childImageSharp.fixed}
                    alt={`Imagen ${index} de ${title}`}
                    title={`Imagen ${index} de ${title}`}
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
        <div className="w-50 pa3">

          <p className="sans-serif tracked ttu f6">{description}</p>
          <span className="sans-serif tracked ttu f6 mb2">by <strong>{author}</strong></span>
          <span className="sans-serif tracked ttu f6"> {date}</span>
        </div>
      </div>
    </>
  )
}

export default HeroPost;
